import React from "react"
import Layout from "../components/layout"

export default function About() {
  return (
    <Layout>
      <div className="about full-page-module">
        <h1>About</h1>
        <div className="about-details">
          Refresh lets you know when high demand items come back in stock and maximizes your chance of completing the checkout process. We hope to save you from endless, unreliable notification sign ups. Sign up in one click via Discord and sit back and wait for your alert. Good luck out there!
        </div>
      </div>
    </Layout>
  )
}
